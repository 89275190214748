<template>
  <div id="Article">
    <div class="wrapper-text">
      <h1>جدیدترین مقالات من</h1>
      <p>
        در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند
        هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد
        بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.
      </p>
      <div class="alert alert-info" role="alert">
        مقالات روی Local Storage مرورگر شما بارگزاری شده اند و تغییرات شما موقت
        می باشد.
      </div>
      <div class="alert alert-success" role="alert">
        چنانچه با نام کاربری و رمز عبور فیک ورود کنید میتوانید این مقالات
        را<strong>ویرایش و حذف</strong> کنید. در منو به بخش نوشتن مقاله بروید و
        <strong>یک مقاله جدید</strong> منتشر نمایید.
      </div>
      <div class="alert alert-primary" role="alert">
        صفحه رفرش شود مقالات مجدد به حالت اولیه بازگردانی می شوند.
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-6 col-lg-4"
        v-for="article in articles"
        :key="article.slug"
      >
        <div class="card mb-3">
          <div
            class="card-header bg-color-primary-4 text-white"
            v-text="article.title"
          ></div>
          <div class="card-body">
            <p class="card-text" v-text="article.description"></p>
            <div class="text-end">
              <router-link
                :to="{ name: 'SingleArticle', params: { slug: article.slug } }"
                class="btn btn-secondary btn-sm"
              >
                <span
                  class="d-inline-flex align-middle mdi mdi-eye me-1"
                ></span>
                بیشتر بخوانید
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Article",
  data() {
    let articles = localStorage.getItem("articles");
    articles = JSON.parse(articles);
    return {
      articles: articles,
    };
  },
};
</script>

<style scoped>
.card-body a {
  font-size: 0.75rem;
}
</style>
